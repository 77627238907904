import * as React from "react";
import styled from "styled-components";
import { graphql, navigate } from "gatsby";
import { WorksPageQuery } from "../../../types/graphql-types";
import Layout from "../../components/Layout";
import { Meta } from "../../components/Meta";
import { theme } from "../../components/theme";
import MediaQuery from "react-responsive";
import { ReactImageGalleryItem } from "react-image-gallery";
import { ImageGalleryView } from "../../components/imageGalleryView";
import { SectionTitle } from "../../components/SectionTitle";
import { SNSLink } from "../../components/SNSLink";
import { Helmet } from "react-helmet";

interface WorksProps {
  data: WorksPageQuery;
}

const Works: React.FC<WorksProps> = ({ data }) => {
  const workList = data.allContentfulWork.edges;

  const imagesSP: ReactImageGalleryItem[] = [
    {
      original: `https://res.cloudinary.com/mogflowts/image/upload/w_768/v1585669084/web/home.jpg`,
    },
    {
      original: `https://res.cloudinary.com/mogflowts/image/upload/w_768/v1585669084/web/home.jpg`,
    },
  ];

  return (
    <Layout>
      <Meta />
      <Helmet>
        <title>{`mogFlowts - Works`}</title>
      </Helmet>

      {/* SP */}
      <MediaQuery query={`(max-width: ${theme.ConstBreakPoint.MAX_SP})`}>
        <Container>
          <TitleContainer>
            <SectionTitle>作品紹介</SectionTitle>
          </TitleContainer>

          <WorksContainer>
            {workList.map((work, index) => {
              return (
                <WorkCard key={work.node.contentful_id}>
                  <img
                    alt={work.node.contentful_id}
                    src={`${work.node.thumbnails[0].file.url}?w=100&fl=progressive`}
                    srcSet={`${work.node.thumbnails[0].file.url}?w=100&fl=progressive 1x, ${work.node.thumbnails[0].file.url}?w=200&fl=progressive 2x, ${work.node.thumbnails[0].file.url}?w=300&fl=progressive 3x`}
                    onClick={() => navigate(`/work/${work.node.contentful_id}`)}
                  />
                  <div>{work.node.title}</div>
                </WorkCard>
              );
            })}
          </WorksContainer>
        </Container>
      </MediaQuery>

      {/* PC */}
      <MediaQuery query={`(min-width: ${theme.ConstBreakPoint.MIN_PC})`}>
        <Container>
          <WorksContainer>
            {workList.map((work, index) => {
              return (
                <WorkCard key={work.node.contentful_id}>
                  <img
                    alt={work.node.contentful_id}
                    src={`${work.node.thumbnails[0].file.url}?w=350&fl=progressive`}
                    srcSet={`${work.node.thumbnails[0].file.url}?w=350&fl=progressive 1x, ${work.node.thumbnails[0].file.url}?w=700&fl=progressive 2x, ${work.node.thumbnails[0].file.url}?w=1050&fl=progressive 3x`}
                    onClick={() => navigate(`/work/${work.node.contentful_id}`)}
                  />
                  <div>{work.node.title}</div>
                </WorkCard>
              );
            })}
          </WorksContainer>
        </Container>
      </MediaQuery>
    </Layout>
  );
};

export const pageQuery = graphql`
  query WorksPage {
    allContentfulWork(sort: { fields: date, order: DESC }) {
      edges {
        node {
          contentful_id
          title
          thumbnails {
            contentful_id
            file {
              url
            }
          }
        }
      }
    }
  }
`;

export default Works;

const TopLogo = styled.div`
  width: 100vw;
  background-color: grey;

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    margin: 15px auto 0;
    width: ${theme.width.main};
  }
`;

const Container = styled.div`
  margin: 0 auto;
  padding: 30px 0 0 0;

  width: 100vw;

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    width: ${theme.width.main};
  }
`;

const TitleContainer = styled.div`
  padding-left: 15px;
`;

const WorksContainer = styled.div`
  margin: 30px auto 0;
  padding: 0 ${theme.width.paddingSideSP};

  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    padding: 0;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }
`;

const WorkCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;

  text-align: center;

  width: calc((100vw - 40px - 20px) / 3);

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    width: calc((${theme.width.main} - 40px - 20px) / 3);
  }

  & > img:nth-child(1) {
    width: 100%;

    justify-self: center;
    border-radius: 8px;

    cursor: pointer;
  }

  & > div:nth-child(2) {
    margin-top: 15px;

    width: 100%;

    color: ${theme.colors.grey60};
    font-weight: 600;

    overflow-wrap: break-word;

    @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
      font-size: ${theme.fontSizes[0]};
    }
  }
`;
